import axios from "axios";
import config from "../config/config.json"
import dataGetPaymentTransbank from "../demo-request/flights_v2/getPaymentTransbank.json"

const endpoint_stg = 'https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg';
const endpoint_dev = 'https://y43e3jv1u1.execute-api.us-east-1.amazonaws.com/dev';
const endpoint_prod = 'https://y1g13tpr7l.execute-api.us-east-1.amazonaws.com/prod';
const endpoint_dev_reg = 'https://7819830ww6.execute-api.us-east-1.amazonaws.com/dev-reg'
const endpoint_dev_assists = 'https://ovhd75c32e.execute-api.us-east-1.amazonaws.com/dev-assists';
const endpoint_dev_alt = 'https://5tom8t3op1.execute-api.us-east-1.amazonaws.com/dev-alt';
const endpoint_dev_soporte = 'https://6ma244jt8b.execute-api.us-east-1.amazonaws.com/dev-soporte/';

const endpoint = endpoint_prod;

export const getPrebooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    'token': config.client.token
  };


  const url = endpoint + '/initSteps'
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}

export const getPayment = async (request) => {

  const url = endpoint + '/initStepPayment';
  const headers = {
    'content-type': "application/json",
    'token': config.client.token
  };

  // const response = dataGetPaymentTransbank;
  const response =  await axios.post(url,JSON.stringify(request),{headers:headers})
  .then(res => {
    //console.log(res);
    return res.data;
  })
  .catch((err) => {
    //console.log(err);
    return false;
  }); 

  return response;

}

export const getConfirmation = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    "token": config.client.token

  };

  const url = endpoint + '/initStepConfirmation';
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}

export const getBooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    "token": config.client.token

  };

   	//const url = 'https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg/lastStep';
    //const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/dev/lastStep';
    //const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/dev-reg/lastStep';
    const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/prod/lastStep';
    //const url = endpoint+'/lastStep';

  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}
