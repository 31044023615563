import React, { Component } from 'react';
import Timer from './Timer';
//import TimerV2 from './TimerV2';
import PurchaseDetail from './PurchaseDetail';

import {Hidden } from '@material-ui/core';

export default class ContainerFixesTop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setActiveStep: 0,
            dataPurchase: []
        }
    }

    render() {
        const { purchaseDetail, timer, products, addToCartPurchase, selectedData, installment, expired, layout, module } = this.props;

       /*  console.log(layout.timer.template) */

        return (
            <div className="container_fixed_top">
                {layout.timer.template === 'timerTopContent' ? (
                    <Hidden mdUp>
                        <Timer layoutTimer={layout.timer} timer={timer} expired={expired} module={module}/>
                    </Hidden> 
                ) : (
                    <Timer layoutTimer={layout.timer} timer={timer} expired={expired} module={module}  />
                )}
                 
                
                <PurchaseDetail
                    installment={installment}
                    selectedData={selectedData}
                    addToCart={addToCartPurchase}
                    purchaseDetail={purchaseDetail}
                    products={products} 
                    module={module}/>
            </div>
        )
    }
}
