import React, { Component, Fragment } from 'react';
import { Paper, Typography, Icon } from '@material-ui/core';
import PopoverSimpleTitleContent from "./layout/PopoverSimpleTitleContent";
import DialogTimer from './layout/DialogTimer';


export default class Timer extends Component {

    state = {
            minutes: this.props.timer.value,
            seconds: 0,
        };
    

    componentDidMount() {
        // Defino el endTime basándome en el estado actual
        const totalMillis = (this.state.minutes * 60 * 1000);
        this.endTime = new Date().getTime() + totalMillis;

        this.myInterval = setInterval(this.tick, 1000);
    }

    tick = () => {
        const now = new Date().getTime();
        const remainingTime = this.endTime - now;

        const totalSeconds = Math.floor(remainingTime / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        if (remainingTime <= 0) {
            clearInterval(this.myInterval);
            this.setState({
                minutes: 0,
                seconds: 0,
            });
        } else {
            this.setState({
                minutes: minutes,
                seconds: seconds,
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    getMessages = (timer) => {
        if (timer.messages) {
            const messages = timer.messages
            return (
                <Fragment>
                {messages.map((message, x) => 
                    <Fragment key={x}>
                        {message.items.map((item, x) => 
                            <Fragment key={x}>                                
                                <span className="icon_right">
                                    <PopoverSimpleTitleContent
                                        className="info_timer"
                                        name="info_timer"
                                        title={item.title}
                                        content={item.description}
                                        icon="info_outline"
                                    />
                                </span>
                            </Fragment>
                        )}
                    </Fragment>
                )}</Fragment>
            )
        }
    }
    
    render() {
        const { /* totalMinutes,  */minutes, seconds } = this.state
        const { layoutTimer, timer, expired, module } = this.props;

        return (
            <Fragment>{layoutTimer[module] &&
                <Paper className={`paper timer ${layoutTimer.template}`}>
                    <span className="icon_left"><Icon>access_time</Icon></span>
                    {this.getMessages(timer)}
                    <div>
                        {/* {console.log("prueba :", this.state.minutes)} */}
                        {(minutes === 0 && seconds === 0) || expired
                            ? <DialogTimer redirect={timer.redirect} info={timer.infoModal} />
                            : <div className="text"><Typography variant="body2"><span className="inner-text">La reserva caducará en: </span><span className="time">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></Typography></div>
                        }
                    </div>
                </Paper>
            }</Fragment>
        )
    }
}
