import React from 'react';

function getProductsParams(module, product, crossell, itemProduc) {
    var arrayItems = [];
    var item_category5 = '';

    // array PRODUCTO seleccionado
    if (product != undefined && itemProduc == true) {
        // set variables Product Selected Item
        var itemName = '';
        var itemId = '';
        var itemPrice = '';
        var itemBrand = '';
        var itemModule = module;
        var itemRegion = '';
        var itemClass = '';
        var itemPeople = '';
        var itemIATA = '';
        var itemVariant = '';

        // Case Flights
        if (module == "airnarrative") {
            console.log('DESDE AIRNARRATIVE')
        }

        // Case Flights
        if (module == "flights") {
            var varFlightType = product.itinerary[0].type;
            itemPrice = Number(product.price).toFixed(2);
            var varPeople = product.passengers.adults + product.passengers.child + product.passengers.infant;
            itemPeople = varPeople + " personas"
            itemClass = ""
            var arrayTramos = [];
            var arraySegmentos = [];

            product.itinerary.map((tramo, i) => {
                var arraySegmentos = [];
                tramo.flights.map((segmento, j) => {
                    arraySegmentos.push({
                        fromIata: segmento.departure,
                        fromCity: segmento.departureCity,
                        fromCountry: segmento.departureCountry,
                        fromDate: segmento.departureTime,
                        toIata: segmento.arrival,
                        toCity: segmento.arrivalCity,
                        toCountry: segmento.arrivalCountry,
                        toDate: segmento.arrivalTime,
                        airlineName: segmento.airlineName,
                        airlineCode: segmento.airline,
                        flightNumber: segmento.flightNumber,
                        flightClass: segmento.cabinClass
                    });
                })
                arrayTramos.push({ arraySegmentos });
            })

            var rutaIata = '';

            arrayTramos.map((tramo, i) => {
                tramo.arraySegmentos.map((segmento, j) => {
                    rutaIata = rutaIata + segmento.fromIata + "-" + segmento.toIata + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                    itemVariant = itemVariant + segmento.flightNumber + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                    itemClass = itemClass + segmento.flightClass + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                    itemBrand = itemBrand + segmento.airlineName + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                })
                itemName = itemName + (i >= 1 ? ";" : "") + tramo.arraySegmentos[0].fromCity + (tramo.arraySegmentos[tramo.arraySegmentos.length - 1].toCity ? "-" : "") + tramo.arraySegmentos[tramo.arraySegmentos.length - 1].toCity;
            })

            itemId = "VL:" + rutaIata;
            itemRegion = product.itinerary[0].cabotage ? "nacional" : "internacional";
            itemIATA = (varFlightType === "roundtrip" || varFlightType === "one way") ? arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toIata : arrayTramos[(arrayTramos.length - 1)].arraySegmentos[(arrayTramos[(arrayTramos.length - 1)].arraySegmentos.length - 1)].toIata;

        } else if (module == "hotels") {
            // Case hotels
            var varStars = product.prebookingResponse.data.hotel.stars;
            var varCountryCode = product.prebookingResponse.data.hotel.country_code;

            itemName = product.prebookingResponse.data.hotel.name;
            itemId = "GM:" + varCountryCode + ":" + itemName;
            itemPrice = Number((product.prebookingResponse.data.packages.totalPrice).toFixed(2));
            itemBrand = product.prebookingResponse.data.packages.providerName;
            itemRegion = product.prebookingResponse.data.hotel.country_code == "AR" ? "nacional" : "internacional";
            itemClass = varStars + " estrellas";

            itemIATA = varCountryCode;
            itemVariant = '';


            var arrayRooms = [];
            var varAdults = 0;
            var varKids = 0;
            var varBoardbase = '';
            var varRoomName = '';
            product.prebookingResponse.data.packages.rooms.map((room, i) => {
                varAdults = varAdults + room.adults;
                varKids = room.kids.age ? varKids + room.kids.count : varKids + room.kids;
                varBoardbase = varBoardbase + (i >= 1 ? "-" : "") + room.boardbase;
                varRoomName = varRoomName + (i >= 1 ? "-" : "") + room.roomName;
            })
            itemPeople = varAdults + varKids + " personas";
            itemVariant = itemBrand + ";" + varRoomName + ";" + varBoardbase;
        } else if (module == "assists") {
            // Case assistance
            // var productData = product.prebookingResponse.offer ? product.prebookingResponse.offer : product.prebookingResponse.results[0];
            var varName = product.product;
            var varZone = product.zone;
            itemRegion = varZone == "Dentro de mi país" ? "nacional" : "internacional";
            itemName = varName;
            itemIATA = varZone;
            itemPrice = product.totalLocalAmount;

            itemId = "AS:" + varZone + ":" + varName;
            itemPeople = product.quantity + " personas";
            itemVariant = product.isFlex ? "flex" : "no flex";
            itemBrand = product.provider;
        } else if (module == "cars") {
            // Case cars
            var productItem = product.prebookingResponse.result;

            itemName = productItem.dataCars.carModel;
            itemId = "AT:" + (productItem.getData.dropOffLocation != "" ? productItem.getData.pickUpLocation + "-" + productItem.getData.dropOffLocation : productItem.getData.pickUpLocation);
            itemPrice = Number(productItem.total);
            itemBrand = productItem.dataCars.companyName.toLowerCase();

            var varPickUpAddress = productItem.getData.pickUpAddress.split(",");
            varPickUpAddress = varPickUpAddress.length == 4 ? varPickUpAddress[3].trim(" ") : varPickUpAddress[2].trim(" ");
            itemRegion = varPickUpAddress == "Argentina" ? "nacional" : "internacional";

            itemClass = productItem.dataCars.categoryName.toLowerCase();;
            itemIATA = productItem.getData.pickUpLocation;
            itemVariant = productItem.rateInfo.name;
        }

        arrayItems.push({
            item_name: itemName,
            item_id: itemId,
            price: itemPrice,
            item_brand: itemBrand,
            item_category: itemModule,
            item_category2: itemRegion,
            item_category3: itemClass,
            item_category4: itemPeople,
            item_category5: itemIATA,
            item_variant: itemVariant,
            quantity: 1
        });
        // console.log('%c getProductsParams() / arrayItems :', 'color: #0D923C; background: #cfe9d8', arrayItems);
    }

    // Arrray de Crossell seleccionado
    if (crossell != undefined && crossell.length !== 0 && !(JSON.stringify(crossell) === '{}')) {
        var varCrossell = crossell.length ? crossell : [crossell]
        varCrossell.map((item, index) => {
            var pax = item.quantityPassengers;

            arrayItems.push({
                item_name: item.cardDetail.name,
                item_id: item.cardDetail.product,
                price: Number(item.priceDetail.amountReal).toFixed(2),
                item_brand: item.cardDetail.provider,
                item_category: "assists",
                item_category2: item.cardDetail.destination == "dentro de mi pais" ? "nacional" : "internacional",
                item_category3: "",
                item_category4: pax + " persona",
                item_category5: item_category5 != '' ? item_category5 : '',
                quantity: 1
            });
        })
    }
    return arrayItems;
}

function getDatalayerParams(datalayerName, module, product, crossell, itemProduc) {

    var varProductsParams = [];
    varProductsParams = getProductsParams(module, product, crossell, itemProduc);

    var itemRutaIATA = '';
    var itemOrigenIATA = '';
    var itemOrigenCiudad = '';
    var itemOrigenPais = '';
    var itemDestinoIATA = '';
    var itemDestinoCiudad = '';
    var itemDestinoPais = '';
    var itemTipoVuelo = '';
    var itemDaysUntilDeparture = '';
    var itemWeeksUntilDeparture = '';
    var itemPax = 0;
    var itemAdultos = 0;
    var itemMenores = 0;
    var itemBebes = 0;
    var itemCurrency = '';

    
    // Case Flights
    if (module == "airnarrative") {
        console.log('DESDE AIRNARRATIVE')
        console.log('DATALAYER', product)
        itemCurrency = product?.priceDetail.currency;
        itemTipoVuelo = "";
        var arrayTramos = [];
        // var arraySegmentos = [];

        // product.itinerary.map((tramo, i) => {
        //     var arraySegmentos = [];
        //     tramo.flights.map((segmento, j) => {
        //         arraySegmentos.push({
        //             fromIata: segmento.departure,
        //             fromCity: segmento.departureCity,
        //             fromCountry: segmento.departureCountry,
        //             fromDate: segmento.departureTime,
        //             toIata: segmento.arrival,
        //             toCity: segmento.arrivalCity,
        //             toCountry: segmento.arrivalCountry,
        //             toDate: segmento.arrivalTime,
        //         });
        //     })
        //     arrayTramos.push({ arraySegmentos });
        // })

        var rutaIata = '';
        // arrayTramos.map((tramo, i) => {
        //     tramo.arraySegmentos.map((segmento, j) => {
        //         rutaIata = rutaIata + segmento.fromIata + "-" + segmento.toIata + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
        //     })
        // })
        rutaIata = product.priceDetail.title.split(' ', 2)[1];
        const [origin, destination] = product.priceDetail.title.split(' ')[1].split('-');

        // console.log('%c arrayTramos :', 'color: #3786B3; background: #C7DFFF', arrayTramos);

        itemRutaIATA = rutaIata;
        itemOrigenIATA = product.cardDetail.originAirport;
        itemOrigenCiudad = origin;
        itemOrigenPais = "";

        itemDestinoIATA = product.cardDetail.destinationAirport;
        itemDestinoCiudad = destination;
        itemDestinoPais = "";

        itemAdultos = "";
        itemMenores = "";
        itemBebes = "";
        itemPax = "";

        itemDaysUntilDeparture = "";
        itemWeeksUntilDeparture = "";
    }

console.log('MODULE', datalayerName)
    // CASE FLIGHTS
    if (module == "flights") {
        itemCurrency = product?.currency;
        itemTipoVuelo = product?.itinerary[0].type;
        var arrayTramos = [];
        // var arraySegmentos = [];

        product.itinerary.map((tramo, i) => {
            var arraySegmentos = [];
            tramo.flights.map((segmento, j) => {
                arraySegmentos.push({
                    fromIata: segmento.departure,
                    fromCity: segmento.departureCity,
                    fromCountry: segmento.departureCountry,
                    fromDate: segmento.departureTime,
                    toIata: segmento.arrival,
                    toCity: segmento.arrivalCity,
                    toCountry: segmento.arrivalCountry,
                    toDate: segmento.arrivalTime,
                });
            })
            arrayTramos.push({ arraySegmentos });
        })

        var rutaIata = '';
        arrayTramos.map((tramo, i) => {
            tramo.arraySegmentos.map((segmento, j) => {
                rutaIata = rutaIata + segmento.fromIata + "-" + segmento.toIata + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
            })
        })

        // console.log('%c arrayTramos :', 'color: #3786B3; background: #C7DFFF', arrayTramos);

        itemRutaIATA = rutaIata;
        itemOrigenIATA = arrayTramos[0].arraySegmentos[0].fromIata;
        itemOrigenCiudad = arrayTramos[0].arraySegmentos[0].fromCity;
        itemOrigenPais = arrayTramos[0].arraySegmentos[0].fromCountry;

        itemDestinoIATA = arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toIata;
        itemDestinoCiudad = arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toCity;
        itemDestinoPais = arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toCountry;

        itemAdultos = product.passengers.adults;
        itemMenores = product.passengers.child;
        itemBebes = product.passengers.infant;
        itemPax = itemAdultos + itemMenores + itemBebes;

        var diffTime = Math.abs(new Date(arrayTramos[0].arraySegmentos[0].fromDate) - new Date());
        itemDaysUntilDeparture = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        itemWeeksUntilDeparture = Math.round((Math.ceil(diffTime / (1000 * 60 * 60 * 24))) / 7);
    }
    // CASE HOTELS
    else if (module == "hotels") {

        // console.log('%c product :', 'color: #3786B3; background: #C7DFFF', product);


        itemCurrency = product.prebookingResponse.data.packages.currency;
        itemRutaIATA = product.prebookingResponse.data.hotel.country_code
        itemDestinoIATA = product.prebookingResponse.data.hotel.country_code;

        var varDestinoCiudad = (product.prebookingResponse.data.infobook.destination).split(",");
        itemDestinoCiudad = varDestinoCiudad[0];
        itemDestinoPais = varDestinoCiudad[1].trim();

        var itemAdultos = 0;
        var itemMenores = 0;
        product.prebookingResponse.data.packages.rooms.map((room, i) => {
            itemAdultos = itemAdultos + room.adults;
            itemMenores = itemMenores + room.kids;
        })
        itemPax = (itemAdultos + itemMenores);

        var diffTime = Math.abs(new Date(product.prebookingResponse.data.infobook.checkin) - new Date());
        itemDaysUntilDeparture = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        itemWeeksUntilDeparture = Math.round((Math.ceil(diffTime / (1000 * 60 * 60 * 24))) / 7);


    }
    // CASE ASSISTS
    else if (module == "assists") {
        // var productData = product.prebookingResponse.offer ? product.prebookingResponse.offer : product.prebookingResponse.results[0];
        itemCurrency = product.currency.toUpperCase();
        var varZone = product.zone
        itemRutaIATA = varZone;
        itemDestinoIATA = varZone;
        itemDestinoCiudad = varZone;
        itemDestinoPais = varZone;

        product.passengers[0].adults.map((pax, i) => {
            var edad = pax.ages[0];
            if (edad <= 1) {
                itemBebes = itemBebes + 1
            } else if (edad > 2 && edad <= 11) {
                itemMenores = itemMenores + 1
            } else if (edad > 11) {
                itemAdultos = itemAdultos + 1
            }
        })
        itemPax = (itemAdultos + itemMenores + itemBebes);
        var diffTime = Math.abs(new Date(product.departureDate) - new Date());
        itemDaysUntilDeparture = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        itemWeeksUntilDeparture = Math.round((Math.ceil(diffTime / (1000 * 60 * 60 * 24))) / 7);
    }
    // CASE CARS
    else if (module == "cars") {
        var productData = product.prebookingResponse.result;
        itemCurrency = product.currency;

        itemOrigenIATA = productData.getData.pickUpLocation;
        itemOrigenCiudad = productData.getData.pickUpLocation;
        var varPickUpAddress = productData.getData.pickUpAddress.split(",");
        itemOrigenPais = varPickUpAddress.length == 4 ? varPickUpAddress[3].trim(" ") : varPickUpAddress[2].trim(" ");

        itemRutaIATA = itemOrigenIATA;

        if (productData.getData.dropOffAddress) {
            itemDestinoCiudad = productData.getData.dropOffLocation;
            itemDestinoIATA = productData.getData.dropOffLocation;
            var varDropOffAddress = productData.getData.dropOffAddress.split(",");
            itemDestinoPais = varDropOffAddress.length == 4 ? varDropOffAddress[3].trim(" ") : varDropOffAddress[2].trim(" ");
            itemRutaIATA = itemOrigenIATA + "-" + itemDestinoIATA;
        }

        var diffTime = Math.abs(new Date(productData.getData.pickUpDate) - new Date());
        itemDaysUntilDeparture = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        itemWeeksUntilDeparture = Math.round((Math.ceil(diffTime / (1000 * 60 * 60 * 24))) / 7);

        var itemPax = "";
        var itemAdultos = "";
        var itemMenores = "";
        var itemBebes = "";
    }

    var datalayerParams = {
        event: datalayerName,
        producto: module,
        rutaIATA: itemRutaIATA,
        origenIATA: itemOrigenIATA,
        origenCiudad: itemOrigenCiudad,
        origenPais: itemOrigenPais,
        destinoIATA: itemDestinoIATA,
        destinoCiudad: itemDestinoCiudad,
        destinoPais: itemDestinoPais,
        tipo_vuelo: itemTipoVuelo,
        days_until_departure: itemDaysUntilDeparture,
        weeks_until_departure: itemWeeksUntilDeparture,
        pax: itemPax,
        adultos: itemAdultos,
        menores: itemMenores,
        bebes: itemBebes,
        currency: itemCurrency,
        items: varProductsParams,
    }
    return datalayerParams;
}

// Trigger DlExtrasAsistencia
export const DlExtrasAsistencia = (datalayerName, module, product, crossell) => {
    var datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, product, crossell, false);

    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);

    // console.log('%c ---- DlExtrasAsistencia / datalayerParams: ', 'color: #E65412; background: #fdeee7', datalayerParams);
}

// Trigger DlExtrasAsistenciaAdd
export const DlExtrasAsistenciaAdd = (datalayerName, module, product, crossell) => {
    var datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, product, crossell, false);
    // console.log('%c ---- DlExtrasAsistenciaAdd / getDatalayerParams: ', 'color: #E65412; background: #fdeee7', datalayerParams)
    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);
}

// Trigger DlExtrasAsistenciaRemove
export const DlExtrasAsistenciaRemove = (datalayerName, module, product, crossell) => {
    var datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, product, crossell, false);
    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);
    // console.log('%c ---- DlExtrasAsistenciaRemove / datalayerParams: ', 'color: #E65412; background: #fdeee7', datalayerParams)
}

// Trigger DlCheckoutBegin
export const DlCheckoutBegin = (datalayerName, module, product, crossell) => {
    // console.log('%c DlCheckoutBegin :', 'color: #717171; background: #eeeeee', datalayerName, module, product, crossell);

    var datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, product, crossell, true);
    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);

    // console.log('%c ---- DlCheckoutBegin / datalayerParams: ', 'color: #E65412; background: #fdeee7', datalayerParams)
}

// Trigger DlSeleccionarFormaPago
export const DlSeleccionarFormaPago = (datalayerName, module, paymentMethod, items) => {
    window.dataLayer.push({
        'event': datalayerName,
        'payment_options': paymentMethod,
    });
    // console.log('%c ---- DlSeleccionarFormaPago / datalayerName: ', 'color: #E65412; background: #fdeee7', datalayerName);
}

export const DlCheckoutSteps = (datalayerName, module, paymentMethod, items) => {
    window.dataLayer.push({
        'event': datalayerName,
        'preventRefresh': true
    });
    // console.log('%c ---- DlCheckoutSteps / datalayerName: ', 'color: #E65412; background: #fdeee7', datalayerName);
}

export const DlProcessingModal = (datalayerName, module, vacio, vacio2) => {
    window.dataLayer.push({
        'event': datalayerName
    });
    // console.log('%c ---- DlCheckoutSteps / datalayerName: ', 'color: #E65412; background: #fdeee7', datalayerName);
}

export const DlPurchaseError = (datalayerName, module, vacio, vacio2) => {
    window.dataLayer.push({
        'event': datalayerName
    });
    // console.log('%c ---- DlCheckoutSteps / datalayerName: ', 'color: #E65412; background: #fdeee7', datalayerName);
}

export const DlPurchase = (datalayerName, module, reservation, items) => {
    window.dataLayer.push({
        'event': datalayerName,
        'transaction_id': reservation.idTransaction,
        'value': Number(reservation?.payments[0]?.installment ? reservation.payments[0].installment.total_amount : null),
        // 'value': Number(reservation.products.pivot[0].data.price),
    });
    // console.log('%c ---- DlPurchase / datalayerName: ', 'color: #E65412; background: #fdeee7', datalayerName);
}