import axios from "axios";
import config from "../config/config.json";
import {getClient} from "./Authentication";
import dataGetPayment from "../demo-request/flights_v2/getPaymentAhora12.json"
import dataGetPaymentTransbank from "../demo-request/flights_v2/getPaymentTransbank.json"
import dataGetPrebookingTransbank from "../demo-request/hotels/getPrebookingTransbank.json"
import dataGetPassenger from "../demo-request/flights_v2/getPassenger.json"
import dataGetLastStep from "../demo-request/flights_v2/lastStepV2.json"
import dataRetrieve from "../demo-request/flights_v2/getRetried105.json";
import dataInitSteps from "../demo-request/flights_v2/initStepsV2ErrorDisponibilidad.json";

const service_dev_hotels = "https://zkbqirko41.execute-api.us-east-1.amazonaws.com/dev-hotels/"
const service_stg = "https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg"
const service_dev = "https://y43e3jv1u1.execute-api.us-east-1.amazonaws.com/dev"
const service_prod = "https://y1g13tpr7l.execute-api.us-east-1.amazonaws.com/prod"
const service_dev_reg = "https://7819830ww6.execute-api.us-east-1.amazonaws.com/dev-reg"
const service = service_prod

/*
 CONSULTAS A SERVICES ----------------------------------------------------------------
*/

export const getPrebooking = async (request) => {
  // console.log("Consultando /initStepsV2...")
  const headers = {
    'Content-Type': "application/json",
    'token': getClient()
  };
  //const response = dataInitSteps; 
  const url = service + '/initStepsV2'

  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}

export const getPayment = async (request) => {

  const url = service + '/initStepPaymentV2';
  const headers = {
    'content-type': "application/json",
    'token': getClient()
  };
  
  // const response = dataGetPaymentTransbank; //{ "errorDecompress": true}; 
  const response =  await axios.post(url,JSON.stringify(request),{headers:headers})
  .then(res => {
    // Obtiene la respuesta encodeada en base64
    return decompressZlibData(res.data);
  })
  .catch((err) => {
    console.log(err);
    return {"errorDecompress": true};
  });

  console.log("response getPayment service", response)
  return response;

}

export const getPassenger = async (request) => {

  const url = service + '/initStepPassengerV2';
  const headers = {
    'content-type': "application/json",
    'token': getClient()
  };
  //const response = dataGetPassenger;
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return { "error": true };
    });

  // console.log("response getPassenger service", response)
  return response;
}

export const getConfirmation = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    "token": getClient()

  };
  const url = service + '/initStepConfirmationV2';
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return { "error": true };
    });

  return response;
}

export const getBooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    "token": getClient()

  };
  //const url = 'https://d2enb6p7uwvvfo.cloudfront.net/stg/lastStep';
  const url = service + "/lastStepV2"

  //const response = dataGetLastStep;
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return false;
    });

  return response;
}

export const retrieveInfoBooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    "token": getClient()

  };
  //const url = 'https://d2enb6p7uwvvfo.cloudfront.net/stg/lastStep';
  const url = service + "/retrieve"

  //const response = dataRetrieve;
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return { "error": true };
    });

  return response;
}

export const retryPayment = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    "token": getClient()

  };
  //const url = 'https://d2enb6p7uwvvfo.cloudfront.net/stg/lastStep';
  const url = service + "/retry"

  //const response = dataRetry;
  const response = await axios.post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return { "error": true };
    });

  return response;
}

/*
 FUNCIONES AUXILIARES ----------------------------------------------------------------
*/

export const decompressZlibData = (data) => {
  let result = null;
  // Obtiene la respuesta encodeada en base64
  let b64Data = data;
  // console.log("Data base64", b64Data)
  const pako = require('pako');
  // Decode base64 (convert ascii to binary)
  let strData = atob(b64Data);
  // Convierte string binario en un array caracter-number
  let charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
  // Convierte array de numbers en un  byte-array
  let binData = new Uint8Array(charData);
  // console.log("data arrayUnit8", binData)
  // Descomprime la data con Pako. 
  // Tambien se le indica que los bytes descomprimidos sean convertidos a string por la misma libreria. 
  var decompressData = pako.inflate(binData, { to: 'string' });
  /* METODO DE DESCOMPRESIÓN ANTERIOR. ------------------------------------------------------
  var decompressData        = pako.inflate(binData);
  console.log("Data descomprimida basica", decompressData) 
  // Conveirte el byteArray descomprimido de nuevo a un string ascii:
  var strDataFinal     = String.fromCharCode.apply(null, new Uint16Array(decompressData));
   ------------------------------------------------------------------------------------------ */
  // console.log("Data descomprimida pasada a STRING", decompressData)
  // Convierte la data en un objeto JSON.
  result = JSON.parse(decompressData);

  return result
}