import React from 'react'
import {Typography, Link} from '@material-ui/core'
import RawMarkup from './RawMarkup'

export default function Copyright(props) {
    const {copyright, footerLinks} = props
    return (
        <div className="copyright">
            {copyright.map((item, i) => <Typography key={i} component="p">{item.item}</Typography>)}
            {/* {copyright.map((item, i) => <RawMarkup key={i} content={item.item} />)}    */}
            {footerLinks.map((item, i) => <Link key={i} className="links-footer" href={item.url} target="_blank"><Typography key={i} component="span">{item.item}</Typography></Link>)}                        
        </div>
    )
}
