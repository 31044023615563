const INITIAL_STATE = {
	prebooking: {},
	preload: true,
	hash: '',
	dataFinal: {},
	allowFinalStep: false,
	startRender: '',
	ip: '',
	userLogin: "",
	responseRetrieve: null,
	responseRetry: null,
	transactionCompleted: null,
	externalRedirect: null
};
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "GET_PREBOOKING_V2":
			// console.log("Response GET_PREBOOKING", action.payload)
			const startRender = (!action.payload) ? action.payload : true;
			return { ...state, userLogin: action.payload.userLogin, startRender: startRender, prebooking: action.payload, preload: false, hash: action.hash, ip: action.ip };
		case "GET_BOOKING_V2":
			console.log("Llego respuesta de lastStepV2 ",action.payload);
			let externalData = null;
			if (action.payload.data?.externalRedirect !== undefined) {
				externalData = action.payload.data.externalRedirect;
			}
			return { ...state, dataFinal: action.payload,prebooking:state.prebooking, allowFinalStep: true, preload: false, externalRedirect: externalData };
		case "UPDATE_PRELOAD_LAYOUT_V2":
			return { ...state, preload: true, prebooking: state.prebooking }
		case "RETRIEVE_V2":
			return { ...state, responseRetrieve: action.payload, transactionCompleted: (action.payload.completed !== undefined && action.payload.completed) ? true : false }
		case "RETRY_PAYMENT_V2":
			return { ...state, responseRetry: action.payload, responseRetrieve: null }
		case "UPDATE_TRANSACTION_COMPLETED":
			return { ...state, transactionCompleted: action.payload }
		default:
			return state;
	}
};
