import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Container, Hidden } from '@material-ui/core'
import Certificates from "./layout/Certificates";
import RawMarkup from './layout/RawMarkup'

export default function Footer(props) {
    const {client} = props
    return (
        <footer >
            <Hidden mdUp> 
                <Container>
                    <Grid container>
                        <Grid item md={5}>
                            <Certificates client={client} />                     
                        </Grid>
                        <Grid item md={7}>
                            {client.copyright.map((item, i) => <Typography key={i} component="p">{item.item}</Typography>)}                        
                            {/* {client.copyright.map((item, i) => <RawMarkup key={i} content={item.item} />)}                    */}
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
        </footer>
    )
}
