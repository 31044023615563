import { getPrebooking, getBooking, retrieveInfoBooking, retryPayment } from "../services/PrebookingServiceV2";
/*para usar data hardcode EJ: ?demo=true&module=flights*/
const windowUrl = window.location.search;
const demo = new URLSearchParams(windowUrl).get("demo");
const publicIp = require('public-ip');

export const getPrebookingAction = async (hash) => {
    let response = null;

    const ipv4 = await publicIp.v4() || "";

    const request = {
        "hash": hash,
        "ip": ipv4
    }
    //console.log(JSON.stringify(request))
    if (demo === "true") {
        const module = new URLSearchParams(windowUrl).get("module");
        //response = await import(`.././demo-request/${module}/getPrebooking_crossell.json`); 
        response = await import(`.././demo-request/${module}/getPrebooking.json`);
        response = response.default
        // console.log("VERSION HARDCODE LOCAL");
    } else {
        // console.log("Action getPrebooking...");
        response = await getPrebooking(request);
    }

    return {
        type: "GET_PREBOOKING_V2",
        payload: response,
        hash: hash,
        ip: ipv4
    }
}

export const getBookingAction = async (ipv4,hash, data, prevStep, info=null) => {

    let response = null;
   
    	// Si el paso tiene un V2 al final del nombre, se lo quita.
	let prevStepName = prevStep != null ? prevStep.stepName.replace('V2', '') : null;
	// Armo request a enviar
	let request = {
		hash: hash,
		ip: ipv4,
		step: prevStepName,
		finishedStep: data,
	};

    if (info!==null){
        request["paymentInfo"] = info
    }
    console.log("Request a enviar a api checkout", request)
    //console.log(JSON.stringify(request))
    if (demo === "true") {
        const module = new URLSearchParams(windowUrl).get("module");
        response = await import(`.././demo-request/${module}/getBooking.json`);
        response = response.default
        //console.log("VERSION HARDCODE LOCAL");
    } else {
        response = await getBooking(request);
    }


    return {
        type: "GET_BOOKING_V2",
        payload: response
    }
}

export const retrieveAction = async (request) => {
    let response = null;

    // console.log("Action retrieveInfoBooking...");
    response = await retrieveInfoBooking(request);

    return {
        type: "RETRIEVE_V2",
        payload: response
    }
}

export const retryAction = async (request) => {
    let response = null;

    // console.log("Action retrieveInfoBooking...");
    response = await retryPayment(request);

    return {
        type: "RETRY_PAYMENT_V2",
        payload: response
    }
}

export const transactionCompletedAction = (boolean) => {
    return {
        type: "UPDATE_TRANSACTION_COMPLETED",
        payload: boolean
    }
}

export const updatePreloadLayoutAction = (boolean) => {
    return {
        type: "UPDATE_PRELOAD_LAYOUT_V2"
    }
}

export const rebootAllowFinalStepAction = (boolean) => {
    return {
        type: "REBOOT_ALLOW_FINAL_STEP_V2",
        boolean: boolean
    }
}
