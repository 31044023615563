import React from "react";
import { createTheme , ThemeProvider, withStyles } from '@material-ui/core/styles';
require('typeface-open-sans');
require('typeface-montserrat');

const hexToRgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
};

const Theme = ({children}) => {

const styles = children.props.styles



const theme = createTheme ({
  typography: { fontFamily: styles.fonts, fontSize: styles.body.fontSize },
  palette: {
    primary: { main: styles.brandPrimary.value},
    secondary: { main: styles.brandSecondary.value}, 
    textColor: { main: styles.body.color},
    background: { default: styles.body.background},
    text: { primary: styles.body.color},
  },
  overrides: {
    MuiAppBar: { colorPrimary: { backgroundColor: styles.navbar.background, color: styles.navbar.color }},
    MuiTypography: { 
      body1: { fontSize: styles.body.fontSize }, body2: { fontSize: '14px'},
      h1: { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h1.fontSize, lineHeight: styles.headings.h1.lineHeight, fontWeight: styles.headings.h1.fontWeight, color: styles.headings.h1.color},
      h2: { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h2.fontSize, lineHeight: styles.headings.h2.lineHeight, fontWeight: styles.headings.h2.fontWeight, color: styles.headings.h2.color },
      h3: { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h3.fontSize, lineHeight: styles.headings.h3.lineHeight, fontWeight: styles.headings.h3.fontWeight, color: styles.headings.h3.color },
      h4: { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h4.fontSize, lineHeight: styles.headings.h4.lineHeight, fontWeight: styles.headings.h4.fontWeight, color: styles.headings.h4.color },
      h5: { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h5.fontSize, lineHeight: styles.headings.h5.lineHeight, fontWeight: styles.headings.h5.fontWeight, color: styles.headings.h5.color, marginBottom: '26px'},
      h6: { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h6.fontSize, lineHeight: styles.headings.h6.lineHeight, fontWeight: styles.headings.h6.fontWeight, color: styles.headings.h6.color },
    },
    MuiChip: { root: { color: 'white' }, deleteIcon: { color: 'rgba(255, 255, 255, 0.26)' } },
    MuiPaper: { rounded: styles.paper.borderRadius },
    MuiFormControlLabel:{ root:{ marginLeft: '-4px', marginRight: '8px' } },
    MuiMenuItem: {
      root:{
          fontSize: '16px'
      }
    },
    MuiBadge: {
      colorError: {backgroundColor: styles.badge.background, color: styles.badge.color}
    }
  }
});

const GlobalCSS = withStyles({
"@global": {
  "body": {
  margin: 0, padding: 0, 
  backgroundColor: styles.body.background, 
  color: styles.body.color, 
  fontFamily: styles.fonts,
  fontSize: styles.body.fontSize,
  "& p":{fontSize: styles.body.fontSize},
  "& #customized-dialog-title":{
    "& .MuiButtonBase-root":{
      color: 'rgba(255, 255, 255, 0.57)',
      "&:hover":{
        color: 'rgba(255, 255, 255, 1)'
      }
    }
  },
  "& .initialDisable": {
    "&.Mui-disabled": { 
      color: styles.headings.h1.color, 
      backgroundColor: '#f7f7f7',
      "& .MuiOutlinedInput-notchedOutline":{
        borderColor: 'rgba(0, 0, 0, 0)',
        "&:hover":{
          "& .MuiOutlinedInput-notchedOutline":{
            borderColor: 'rgba(0, 0, 0, 0)'
          }
        } 
      }   
    },
    "& .Mui-disabled.MuiInputBase-root": { 
        color: styles.headings.h1.color, 
        backgroundColor: '#f7f7f7',
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: 'rgba(0, 0, 0, 0)',
          "&:hover":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor: 'rgba(0, 0, 0, 0)'
            }
          } 
        }     
    },
    "& .MuiOutlinedInput-root": { 
      "& .Mui-disabled": { 
        color: styles.headings.h1.color, 
        backgroundColor: '#f7f7f7'           
     }      
    },
    "& .Mui-disabled.MuiFormLabel-root":{ backgroundColor: 'transparent' }
  },
  "& .MuiFormControl-root": {
    paddingTop: "22px", marginBottom: "12px", 
    //overflow: "hidden", 
    //sin overflow por los mensajes de validación
    [theme.breakpoints.down('sm')]: {marginBottom: "12px"},
    "&.radio": { paddingTop: '0', [theme.breakpoints.up('md')]: {paddingTop: '23px'}, "& div": {[theme.breakpoints.up('md')]: {justifyContent: "center"}}},
    "&.checkbox": {paddingTop: "0"},
    "&.initialInput": { "& .MuiOutlinedInput-root": { "&.Mui-error": { "& .MuiOutlinedInput-notchedOutline": {borderColor: 'rgba(0, 0, 0, 0.23)'} } }},
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute", zIndex: "1", 
      top: "inherit", bottom: "44px", 
      right: "5px", left: "auto",       
      margin: "0", borderRadius: "4px", maxWidth: "70%", padding: "8px 10px", backgroundColor: styles.errorColor, color: "white",
      fontSize: "13px", lineHeight: "16px",
      "&:before": { content: '""', position: "absolute", border: "8px solid", top: "auto", right: "7px", bottom: "-16px", left: "auto", borderColor: styles.errorColorTriangle },
      "&#payment[getwayPayment][expiry]-helper-text":{maxWidth: "50%", fontSize: "12px", lineHeight: "14px"}
    },
    "& .MuiFormLabel-root.Mui-focused": { color: styles.inputs.label.focus.color },
    "& .MuiInputBase-input": {
      boxSizing: 'border-box',
      padding: '14px',
      fontSize: styles.inputs.fontSize,
      lineHeight: styles.inputs.lineHeight,
      height: styles.inputs.height,
      '&::placeholder': { textOverflow: 'ellipsis !important', fontStyle: styles.inputs.placeholder.fontStyle, color: styles.inputs.placeholder.color }},
    "& .MuiFormLabel-root": { 
      color: styles.inputs.label.color, 
      transform: "none", 
      whiteSpace: "nowrap", 
      fontSize: styles.inputs.label.fontSize,
      fontWeight: styles.inputs.label.fontWeight,
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%",
      lineHeight: '16px'
      //[theme.breakpoints.down('lg')]: { fontSize: '18px'  } 
    },
    "& fieldset legend": { display: "none" },
    "& .MuiOutlinedInput-notchedOutline": { top: 0 },
    /* "&.mui-error-large":{
      "& .MuiFormHelperText-root.Mui-error": {
        top: '-20px'
      }
    }, */
    "&.datepicker":{
      "& .MuiFormHelperText-root.Mui-error": {
        //top: '-20px',
        maxWidth: '60%'
      }
    }
  },
  "& .group-inputs" : {
    position: 'relative',
    "& .label-group" : {
      color: styles.inputs.label.color,
      fontSize: styles.inputs.label.fontSize,
      fontWeight: styles.inputs.label.fontWeight,
      top: 0,
      left: 0,
      position: 'absolute'      
    }
  },
  "& .MuiOutlinedInput-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline": {borderColor: styles.inputs.border.focus},
  "& .MuiIconButton-root": {
    color: styles.iconColor, 
    fontSize: 'inherit',
  },
  "& .MuiAppBar-colorPrimary": { [theme.breakpoints.down('sm')]: {boxShadow: 'none'} },
  "& .MuiTypography-h1": { [theme.breakpoints.down('sm')]: {fontSize: '22px'} },
  "& .MuiTypography-h2": { [theme.breakpoints.down('lg')]: {fontSize: '20px'}, [theme.breakpoints.down('sm')]: {fontSize: '18px'} },
  "& .title-h4": { 
    fontFamily: styles.headings.fontFamily, 
    fontSize: styles.headings.h4.fontSize+'px!important', 
    lineHeight: styles.headings.h4.lineHeight, 
    fontWeight: styles.headings.h4.fontWeight+'!important', 
    color: styles.headings.h4.color,
    "& .MuiIcon-root": {}
  },
  "& .title-h3": { fontFamily: styles.headings.fontFamily, fontSize: styles.headings.h3.fontSize+'px!important', lineHeight: styles.headings.h3.lineHeight, fontWeight: styles.headings.h3.fontWeight+'!important', color: styles.headings.h3.color },
  "& .MuiContainer-root": { [theme.breakpoints.up('md')]: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) } },
  "& .MuiToolbar-root": { maxWidth: "1280px", margin: "auto", width: "100%" },
  "& .MuiButton-root": { 
    padding: '11px 16px', 
    textTransform: 'none', 
    color: styles.linkColor, 
    [theme.breakpoints.up('sm')]: { padding: '6px 8px' }, 
    [theme.breakpoints.up('lg')]: { padding: '11px 16px' },
    "&:hover":{color: styles.brandPrimary.value},
    
  },
  "& .MuiButton-sizeSmall":{ fontSize: '.85rem', height: "45px" },
  "& .MuiButton-containedSizeSmall": { padding: '4px 12px' },
  "& .MuiButton-outlinedSizeSmall": { padding: '3px 14px' },
  "& .MuiButton-outlined": { color: styles.buttons.default.background, borderColor: styles.buttons.default.background, "&:hover": {color: styles.buttons.default.hover.background, borderColor: styles.buttons.default.hover.background } },
  "& .MuiButton-outlinedPrimary": { color: styles.buttons.primary.background, borderColor: styles.buttons.primary.background, "&:hover": { color: styles.buttons.primary.hover.background, borderColor: styles.buttons.primary.hover.background } },
  "& .MuiButton-outlinedSecondary": { color: styles.buttons.secondary.background, borderColor: styles.buttons.secondary.background, "&:hover": { color: styles.buttons.secondary.hover.background, borderColor: styles.buttons.secondary.hover.background } },
  "& .MuiButton-contained": { backgroundColor: styles.buttons.default.background, "&:hover": { backgroundColor: styles.buttons.default.hover.background },
    "& .MuiButton-label": { color: styles.buttons.default.color, whiteSpace: "nowrap" },
    "&.MuiButton-containedPrimary": { 
      color: styles.buttons.primary.color, 
      backgroundColor: styles.buttons.primary.background,
      /* "&.disabled":{
        opacity: '.8'
      }  */
    }
  },
  "& .MuiIcon-root": { fontSize: '1.5rem' },
  "& .MuiTypography-h4": { "& .MuiIcon-root": { fontSize: styles.headings.h4.fontSize, lineHeight: styles.headings.h4.lineHeight } },
  "& .MuiTypography-body1": { fontSize: styles.body.fontSize },
  "& .MuiPaper-root": { fontSize: styles.body.fontSize, 
    "&:not(.MuiPopover-paper)":{
      [theme.breakpoints.down('sm')]: { width: '100%' } 
    }
    
  },
  "& .stepper_container": { backgroundColor: 'rgba(255, 255, 255, 0.5)', "& .MuiPaper-root": { backgroundColor: 'transparent' },
    "& .stepper_component": { maxWidth: '1170px', margin: 'auto', [theme.breakpoints.down('sm')]: { padding: '12px' } },
    "& .MuiStep-horizontal": { [theme.breakpoints.down('sm')]: { paddingLeft: '4px', paddingRight: '4px' } },
    "& .MuiStepLabel-iconContainer": { [theme.breakpoints.down('sm')]: { paddingRight: '4px' } },
    "& .MuiTypography-root": { textTransform: 'capitalize', [theme.breakpoints.down('sm')]: { fontSize: '13px' } },
    "& .MuiSvgIcon-root": { [theme.breakpoints.down('sm')]: { width: '18px', height: '18px'} }
  },
  "& .wrapper": { display: 'flex', flexDirection: 'column', },
  "& .main": {[theme.breakpoints.up('md')] : {minHeight: 'calc(100vh - 176px)'} },
  "& .paper": { 
    padding: '16px', 
    [theme.breakpoints.down('sm')]: { padding: '12px', overflow: 'visible' },
    "&.paper-form": {
      overflow:'visible',
      [theme.breakpoints.up('md')]:{
        //paddingTop: '24px',
        paddingBottom: '8px'
      },
      "& .title-contact-form": {
        marginTop: '10px'
      },
      "& .title-billing": {
        marginTop: '25px'
      }
    } 
  },
  "& .messages_advice": {
    position:'relative',
    padding: '8px',
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: { 
      marginTop: '20px',
      borderTop: "4px solid", 
      borderColor: styles.iconColor,
      borderRadius: "4px"
    }, 
    "&.advice-restrictions":{
      padding: '0',
      [theme.breakpoints.down('sm')]:{
        padding: '0'
      }
    },
    "& .advice_content": { 
      "& li:not(:last-child)": { marginBottom: '8px' }, 
      [theme.breakpoints.up('md')]: { paddingLeft: '40px' }, 
      [theme.breakpoints.down('sm')]: { 
        fontSize: '13px', 
        //paddingRight: '20px',
        paddingRight: 0         
      },
      "& p": {
        fontSize: '13px',
      }
    },
    "&.advice-fechas-flexibles":{
      backgroundColor: styles.iconColor,
      "& p": { 
        fontSize: '13px', color: 'white',
        "&.title": {
          fontSize: '16px',
          fontWeight: '600'
        }
      },
      "& li":{
        marginBottom: '0!important'
      },
      "& .advice_icon":{
        color: 'white'
      }
     
    },
    "& .advice_content_restrictions": { 
      "& li:nth-child(even)":{ backgroundColor: '#f8f8f8'},
      "& li:not(:last-child)": { marginBottom: '0' }, 
      "& p": {
        fontSize: '13px',
        position: 'relative', 
        //borderRadius: '5px',
        //margin: "-6px -6px", 
        padding: "6px", paddingLeft: "45px",
        [theme.breakpoints.down('sm')]: { fontSize: '13px', paddingRight: '20px' },
        "& img" :{
          position: 'absolute', left: "12px", top: "calc(50% - 8px)"
        }
      }
      
    },
    "& .advice_icon": { 
      color: styles.iconColor, 
      position: 'absolute', 
      [theme.breakpoints.up('md')]: { left: '12px', top: '12px' }, 
      [theme.breakpoints.down('sm')]: { 
        display: 'none',
        position: "absolute", 
        width: "36px", height: "36px",
        top: "-12px", right: "-4px", 
        padding: "9px 8px 0", 
        background: styles.iconColor, color: "#ffffff", 
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", 
        borderRadius: "50%", 
        "& .material-icons": { fontSize: '1.3rem' }} 
    },
    "&.messageAlert": { backgroundColor: styles.messages.messageAlert.background, color: styles.messages.messageAlert.color, "& .advice_icon": { backgroundColor: styles.messages.messageAlert.background, color: styles.messages.messageAlert.color }},
    "&.messageDetailTopAlert": { backgroundColor: styles.messages.messageAlert.background, color: styles.messages.messageAlert.color, "& .advice_icon": { backgroundColor: styles.messages.messageAlert.background, color: styles.messages.messageAlert.color } }
  },
  "& .advice-information_b":{
    padding: '16px',
    "& .title-h4":{
      
      marginBottom: '12px', 
      paddingLeft: '30px', 
      position: 'relative',
    "& .MuiIcon-root": {position: 'absolute', left: 0, color: styles.iconColor}
  }
  },
  
  "& .paper .messages_advice":{
    backgroundColor: '#f8f8f8',
    borderRadius: '4px'
  },
  "& .advice-messageTopSidebar":{
    "& .MuiTypography-root":{fontSize: '13px'},
    "& .advice_content":{paddingRight: '20px'}
  },
  "& .advice-information-v2":{ "& p": { fontSize: '14px' } },
 /*  "& .carousel_crossell_title":{
    [theme.breakpoints.up('md')]:{
      marginLeft: '12px',
    } 
  }, */
  "& .stepPassenger":{
    "& .messages_advice":{ 
      marginBottom: '32px',
      [theme.breakpoints.down('sm')]:{
        marginBottom: 0,
        //marginTop: 0
      },
      "&.advice-messageInfoPassengers":{
        [theme.breakpoints.down('sm')]:{
          marginBottom: '15px',
          marginTop: 0
        }
      },
    }
  },
  
  "& .crossell_products": {
    "& .slick-prev, \n  & .slick-next": { width: "40px", height: "40px", "&:before": { display:'none', fontSize: "40px", color: "#9a9a9a" } },
    "& .card_product": { 
      marginBottom: "16px",
      "& .card_header_content": { padding: '0 16px' },
      "& .card_product_header": { color: styles.headings.alternative.color, borderBottom: "1px solid #dddddd", padding: "8px", paddingTop: "20px", '& img': { margin: "auto", marginBottom: "12px" } },
      "& .card_product_footer": { width: '100%', borderTop: "1px solid #dddddd", padding: "12px", "& .card_product_footer_content": { width: "100%" } },
      "& .card_product_content": { padding: "12px", paddintTop: "18px", "& .detail_item_list": {"& p": { fontSize: "13px", [theme.breakpoints.down('sm')]: { fontSize: "13px" } }} },
      "&.CardOneColV2":{ 
        "& .card_header_content": { padding: '0' },
        "& .img_provider img":{ marginBottom: "4px"}, 
        "& .img_product img":{ marginBottom: "0"} 
      },
      "&.card_product_brandedFares":{
        backgroundColor: `rgba(${hexToRgb(styles.linkColor)}, 0.08)`,
        marginTop: 30,
        "& .card_product_header": {
          
          paddingTop: '8px',
        },
        "& .card_product_content":{
          minHeight: 210,
          backgroundColor: 'white'
        },
        "& .card_product_footer":{
          backgroundColor:'#FBFBFB',
          "& .por-persona" :{
            display: 'block',
            marginBottom: 10
          },
          "& .precio-total" :{
            display: 'block',
            fontSize: 12
          },
        },
        "& .button_add_product":{

        }
      },
      "& .tarifa-seleccionada":{
        visibility: 'hidden',
        padding: '4px',
        textAlign: 'center',
        height: 30
      },
      "&.current":{
        border: '1px solid',
        borderColor: styles.infoColor,
        marginTop: 0,
        "& .tarifa-seleccionada":{
          visibility: 'initial',
          backgroundColor: styles.infoColor, color: 'white'
        },
      }
    },  
    "& .slick-list": { margin: "0 -8px" },
    "& .carousel_item": {padding: "8px",[theme.breakpoints.down('sm')]: {  padding: "0 2px" }, "&:focus": { outline: "none" }, "& .componentDetailItems":{ padding: 0, margin: 0, border: 'none' }},
    "& .button_carousel": { fontSize: "0", lineHeight: "0", position: "absolute", top: "50%", display: "block", width: "20px", height: "20px", padding: "0", transform: "translate(0, -50%)", cursor: "pointer", color: "#9a9a9a", border: "none", outline: "none", background: "transparent",
      "&.button_carousel_prev": { left: "-14px" },
      "&.button_carousel_next": { right: "-14px" }, [theme.breakpoints.down('sm')]: { display: 'none' }
    },
    "& .header_step": {display: 'none'}
  },
  "& .crossell_resume": { overflow: 'hidden!important',
    "& .detail_item": { marginTop: '10px' },
    "& .col-right": { height: '100%', [theme.breakpoints.up('md')]: { paddingTop: '55px'}},
    "& .button_remove_product": { [theme.breakpoints.up('md')]: { /* marginTop: '25px' */ }},
    "& .detail_item_list": { "& p": { [theme.breakpoints.down('sm')]: { fontSize: "13px" }}},
    "& .componentDetailItems":{border: 'none', marginBottom: "0", paddingBottom: "0", "& .ellipsisx3":{maxWidth: "inherit"}},
    "& .button-dialog_single_card":{
      padding: "0", fontSize: "14px",
      "&:hover":{backgroundColor: 'transparent'},
      "& .MuiButton-startIcon.MuiButton-iconSizeSmall" : { 
        marginLeft: '0', marginRight: "4px", "& .MuiIcon-root":{fontSize: "22px",}
       }
    },
    "& .messageConditions": {
      "& p":{ position: "relative", paddingLeft: "25px", fontSize: "14px"},
      "& .MuiIcon-root":{ fontSize: "22px", position: "absolute", left: "0", top: "-3px", color: styles.iconColor, marginRight: "4px" }
    },
    "& .title_product":{color: styles.headings.alternative.color, fontWeight: 600, fontSize:'22px'},
  },
  "& .crossell_resume.crossell_resume_single_card":{
    "& .promo_label": {backgroundColor: styles.promoLabel.background, display: "inline-block", float: "right", top: "10px"},
    "& .col-right": { [theme.breakpoints.up('md')]: { paddingTop: 0}},
    "& .detail_price_product":{ 
      
      [theme.breakpoints.up('md')]: { marginTop: '40px' }
    } 
    
  },
  "& .crossell_resume_default_branded_fares":{
    "& .price_offer":{display: 'none'},
    "& .detail_item_list":{
      "& .list_item":{
        marginBottom: 4,
        "& .title":{fontSize: 14, display: 'inline-block'},
        "& .description":{fontSize: 14, display: 'inline-block'},
        "& .has_icon .custom_icon":{top: 4}
      }
    },
    
  },
  "& .crossell_resume.crossell_resume_default":{
    "& .CardTwoCols":{
      "& .MuiGrid-grid-md-3": {
        [theme.breakpoints.up('sm')]: { 
          maxWidth: "18%", 
          flexBasis: "18%"
        }
      },
      "& .MuiGrid-grid-md-9": {
        [theme.breakpoints.up('sm')]: { 
          maxWidth: "82%", 
          flexBasis: "82%"
        }
      }
    },
    "& .messageConditions":{marginTop: '15px'},
    "& .col-right": {[theme.breakpoints.up('md')]: { paddingTop: '55%'}},
    "& .button_remove_product": {marginTop: '15px'}
  },
  "& .cardProductDialogSingleCard":{
    "& .messageConditions": {
      "& p":{ position: "relative", paddingLeft: "25px", fontSize: "14px"},
      "& .MuiIcon-root":{ fontSize: "22px", position: "absolute", left: "0", top: "-3px", color: styles.iconColor, marginRight: "4px" }
    },
  },
  "& .product_card_category": { textTransform: 'uppercase', /* marginBottom: '10px', */ color: styles.headings.alternative.color },
  "& #cardProductDialog": { "& .dialog_card_product_title": { marginTop: "5px", marginBottom: "5px", color: styles.headings.alternative.color },"& .dialog_card_product_icon": { color: styles.headings.alternative.color, position: 'relative', top: '5px' }},
  "& .card_detail_product_content": {
    "& .img_provider": { [theme.breakpoints.up('md')]: { float: 'right' }}, 
    "& .autos": {"& .img_provider": { [theme.breakpoints.up('md')]: { float: 'none' } }},
    "& .product_card_subtitle": { marginBottom: '16px' }},
  "& .detail_item_tags": { marginBottom: "12px", marginRight: '8px', position: 'relative', top: '-2px' },
  "& .detail_item_title": { verticalAlign: "top", marginBottom: '16px' },
  "& .componentDetailItems": { 
    marginBottom: '16px', paddingBottom: '10px', borderBottom: '1px solid #e1e1e1',
    "&:last-child":{  marginBottom: "0", border: "none" } 
  },
  "& .dialog_card_product_content": {
    "& .img_provider": { [theme.breakpoints.up('md')]: { float: 'right' } },
    "& .autos": {"& .img_provider": { [theme.breakpoints.up('md')]: { float: 'none' } }},
    "& .detail_item": { marginTop: '16px'},
    "& .detail_item_list": { marginRight: '10px', "& p":{ [theme.breakpoints.down('sm')]: { fontSize: "13px" } } }
  },
  "& .copyright":{
    padding:'5px 0 25px',
    "& .MuiTypography-root": { 
      fontSize: styles.footer.fontSize,
      color: '#999999'
    },
    "& a": {
      color: '#999999'
    }
  },
  "& .links-footer":{
    display: 'inline-block',
    marginRight: '4px'
  },
  "& .logos-certificates": { 
    textAlign: "center", marginBottom: '16px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    [theme.breakpoints.up('md')]: { 
      height: styles.footer.height_logos 
    },
    "& .logo-footer":{
      marginRight: '12px', 
      display: 'inline-block',
      
      "& img" : { 
        maxWidth:'100%',
        maxHeight: '60px'
      }
    } 
  },
  "& footer": { 
    [theme.breakpoints.up('md')]:{
      paddingBottom: "90px"
    },
    [theme.breakpoints.down('sm')]: {
      padding: "25px 0",  
      backgroundColor: styles.footer.background, 
      color: styles.footer.color
    },
      "& .MuiTypography-root": { 
        [theme.breakpoints.down('sm')]: { 
          textAlign: "center",
          fontSize: styles.footer.fontSize
         } 
      }
  },
  "& .footer-final-view": {
    padding: "25px 0",  
    backgroundColor: styles.footer.background, 
    color: styles.footer.color,
    "& .MuiTypography-root": { 
      [theme.breakpoints.down('sm')]: { 
          textAlign: "center",
          fontSize: styles.footer.fontSize
      } 
    }
  },
  "& .MuiStepLabel-root": { "& .MuiStepIcon-active": { color: styles.stepper.label.color }, "& .MuiStepIcon-completed": { color: styles.stepper.label.completed.color } },
  "& .MuiStepLabel-root.Mui-disabled": { "& .MuiStepIcon-root": { color: 'white', border: '1px solid rgba(0, 0, 0, 0.4)', borderRadius: '50%'}, "& .MuiStepIcon-text": { fill: '#a0a0a0' } },
  "& .MuiStepLabel-label.MuiStepLabel-active": { color: styles.stepper.label.color },
  "& .MuiStepLabel-label.MuiStepLabel-completed": { color: styles.stepper.label.completed.color },
  "& .MuiPaper-rounded": { borderRadius: styles.paper.borderRadius },
  "& .timer": { color: styles.timer.color, backgroundColor: styles.timer.background, marginBottom: "20px", position: "relative", padding: "8px", 
    [theme.breakpoints.down('sm')]: {
      padding: "3px 0!important" 
    },
    "& .icon_left,\n    & .icon_right": { display: "inline-block", position: "absolute", top: "13px", "& .material-icons": { fontSize: "1.4em !important" },
      [theme.breakpoints.down('sm')]: {
        top: "6px" 
      }
    },
    "& .icon_left": { left: "8px" },
    "& .icon_right": { right: "8px" },
    "& .info_timer": { color: styles.timer.color, padding: "0px 4px", verticalAlign: 'top' },
    "& .MuiIcon-root": { [theme.breakpoints.down('sm')]: { fontSize: "1.3rem", lineHeight: "1.3rem" } },
    "& .text": { verticalAlign: "top", padding: '0 30px', display: 'inline-block', 
      [theme.breakpoints.down('sm')]: { textAlign: 'center', display: 'block' } },
    "& .inner-text": { lineHeight:'22px', display: "inline-block", verticalAlign: "top", marginTop: "6px", marginRight: "8px",
      [theme.breakpoints.down('sm')]:{
        marginTop: "3px"
      }
    },
    "& .time": { fontSize:'22px', display: "block",
      [theme.breakpoints.down('sm')]: {
        fontSize: "16px", marginTop: "2px", display: "inline-block"
      }
    },
    "&.timerv2":{
      "& .time":{fontSize:'35px', marginTop: '0'},
      "& p": { lineHeight:'18px' },
      "& .icon_left":{
        position: "relative", left: 0, top: '0', 
        "& .material-icons": { fontSize: '30px !important' }},
      "& .timer_icon":{
        position: 'absolute', 
        [theme.breakpoints.up('md')]: { left: '12px', top: '22px' }, 
        [theme.breakpoints.down('sm')]: { 
          top: "-12px", right: "-4px", width: "36px", height: "36px", padding: "9px 8px 0",  
          "& .material-icons": { fontSize: '1.3rem' }} 
      },
      "& .timer_content":{
        [theme.breakpoints.up('md')]: { paddingLeft: '40px' }, 
        [theme.breakpoints.down('sm')]: { paddingRight: '20px' },
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        "& .col_text":{
          width: "100%",
          flexGrow: "0",
          maxWidth: "87%",
          flexBasis: "87%",
          "& .title_timer":{marginBottom: '4px', letterSpacing: "-0.06px" }
        },
        "& .col_number":{
          width: "100%",
          flexGrow: "0",
          maxWidth: "13%",
          flexBasis: "13%"
        }
      }
    },
    "&.timerSmall":{
      "& .time": { fontSize:'28px', display: "inline-block", lineHeight: '23px', verticalAlign: 'top',
        [theme.breakpoints.down('sm')]: {
          fontSize: "16px", marginTop: "2px", display: "inline-block"
        }
      },
      "& .inner-text":{ marginTop: "0" },
      "& .icon_left,\n    & .icon_right": {
        top: '8px',
        [theme.breakpoints.down('sm')]: {
          top: "5px" 
        }
      }
    }
  },
  "& .container_steps": { 
    [theme.breakpoints.down('sm')]: { padding: '8px', minHeight: '200px' },
    [theme.breakpoints.up('md')]: { minHeight: '55vh' },
    [theme.breakpoints.up('lg')]: { minHeight: '60vh' }
   },
  "& .steps_container": { 
    
    [theme.breakpoints.up('md')]: { 
      // css para el footer alineado abajo del contenedor
      height: '100%',
      paddingBottom: styles.footer.height,
      position: 'relative',
      // end
     },
    [theme.breakpoints.down('sm')]: { 
      minHeight: 'calc(100vh - 357px)'
     },
    "& section":{ 
      [theme.breakpoints.down('sm')]: { 
        marginBottom: '0',
        overflow: 'hidden' 
      } 
    }, 
    "& .stepConfirmation":{ 
      "& .form-assist-crossell-container": {
        padding: '0',
      }, 
      "& .card_detail":{ "& .paper":{ padding: "0", border: "none", background: "none", boxShadow: "none", borderRadius: "0" }}}, 
      "& .form-passenger":{
        width: "100%"
      },
      "& .form-default-subtitle":{
        margin: 0,
        padding: 0
      },
      "& .subtitle-crossell":{
        marginTop: '12px',
        marginBottom: '18px'
      }
    },
  "& .container_booking": {[theme.breakpoints.down('sm')]: { padding: '8px', minHeight: '500px' } },
  "& .creditCardDialog": {
    "& .MuiDialog-paper": { [theme.breakpoints.down('sm')]: { borderRadius: 0, margin: 0, maxHeight: '100%', minHeight: '100vh' }},
    "& .MuiDialogContent-root": { backgroundColor: '#F0F0F0', padding: 0, border: 'none', height: '80vh', overflowY: 'scroll' },
    "& .MuiDialogTitle-root": { 
      backgroundColor: styles.cards.header.background, 
      padding: 0, 
      "& .MuiIconButton-root": { color: styles.cards.header.color }
    }
  },
  "& .cvc_container":{ 
    position: 'relative', 
    "& .button-SecurityCodeCardDialog":{ 
      position: "absolute", right: "0", zIndex: "1", top: '18px', "& .custom_icon":{ width: '40px', height: '36px' }, "& img":{maxWidth: '45px'}}},
  "& .payment_steps": { width: '100%', position: 'relative', height: '80vh', "& .MuiStep-root": { display: 'none' }, "& .payment_steps_back_button": { padding: '0 8px', position: 'sticky', bottom: '0', width: '100%', background: '#f1f1f1' }},
  "& .main-title.MuiTypography-h1": {
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
      marginBottom: '0',
      fontSize: '25px'
    }
   },
  "& .header_step": { 
    marginBottom: '12px', 
    [theme.breakpoints.down('sm')]: { 
      padding: '12px 8px', margin: '0 -8px', 
     /*  backgroundColor: 'rgba(255,255,255,.2)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)', 
      marginBottom: '16px' */
    },
    "& .title_step": { 
      color: styles.headings.h1.color, 
      marginBottom: '0', 
      fontSize: '26px',
      [theme.breakpoints.down('sm')]: { 
        marginBottom: 0,
        fontSize: '22px', 
      } 
    },
    '& .icon_step': { backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50%', width: '40px', height: '40px', padding: '8px', [theme.breakpoints.down('sm')]: { maxHeight: '40px' }, '& .material-icons': { fontSize: '1.8em' } },
    '& .text': { verticalAlign: 'top', marginTop: '4px', display: 'inline-block' },
    '& .step_header_icon': { backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50%', width: '40px!important', height: '40px!important', padding: '8px', fill: styles.headings.h1.color, [theme.breakpoints.down('sm')]: { maxHeight: '40px', display: 'none' }}
  },
  "& .form-default-subtitle": {
    padding: '24px 12px 8px',
    "& .MuiTypography-root.MuiTypography-h2": { 
      color: styles.body.color
    },
    "&.subtitle-hoteles":{
      "& .MuiTypography-root.MuiTypography-h2": { 
        fontSize: "16px",
        textTransform: 'uppercase',
        color: '#585d61',
        "& .material-icons":{fontSize: "14px"} 
      }     
    },
    "& .subtitle-crossell":{
      marginBottom: "16px",
      marginTop: "32px"
    }
  },
  "& .header_payment_method": { backgroundColor: styles.cards.header.background, color: styles.cards.header.color, overflow: 'hidden', padding: '16px',
    "& .header_payment_title": { color: styles.cards.header.color, margin: "0", marginBottom: '10px'},
    "& .header_payment_icon": { color: styles.cards.header.color, position: 'relative', top: '6px', fill: 'white', marginRight: '4px' },
  },
  "& .step_payment": { position: 'relative', [theme.breakpoints.up('md')]: { minWidth: "500px" },
    "& .header_step_payment": { backgroundColor: '#f5f5f5;', overflow: 'hidden', padding: '16px', position: 'sticky', top: '0', width: '100%', zIndex: 1, "& .step_payment_title": { margin: "0" }, "& .item_resume_price": { padding: "12px", border: "1px solid white", borderRadius: "12px" }},
    "& .body_step_payment": { 
      backgroundColor: styles.body.background,  padding: '12px', 
      "& .MuiListItem-container": { marginBottom: '12px' }, 
      "& .breadcrumb": { margin: "0 0 4px", 
        "& .MuiBreadcrumbs-ol": { margin: "0", display: "flex", padding: "0", flexWrap: "wrap", listStyle: "none",   alignItems: "center",   
          "& .MuiBreadcrumbs-separator": { display: "flex", marginLeft: "8px", userSelect: "none", marginRight: "8px"     }   
        }, 
        '& p': { fontSize: "13px", fontWeight: "600", /* color: "#303030" */ } 
      }, 
      "& .MuiListItemText-root": { position: "relative", paddingRight: "45px", "& .icon_arrow": {   position: "absolute",   right: "0",   top: "0",   bottom: 0,   margin: 'auto'   } }}
  },
  "& .steps_buttons": { 
    transition: "all 0.2s ease-in-out 0s", 
    position: 'fixed', bottom: 0, right: 0, left: 0, backgroundColor: 'rgba(255, 255, 255, 0.90)', padding: '16px', textAlign: 'right', width: "100%", zIndex: 200,
    [theme.breakpoints.up('md')]: {
      boxShadow: '0 -4px 10px 0 rgba(0,0,0,.2)'
    },
    [theme.breakpoints.down('sm')]: {
      position: "relative", padding: "0", background: "none", marginBottom: "45px"
    }, 
    "& .steps_buttons_inner": { bottom: '50px', "& .text_total_sumary": { [theme.breakpoints.down('sm')]: {   display: 'none'   }, fontSize: "18px", lineHeight: "22px", verticalAlign: "top", margin: "10px 20px 0 0", display: "inline-block", "& .text": {   verticalAlign: "top",   display: "inline-block",   padding: "12px 4px"   }, "& .price": {   display: "inline-block",   fontWeight: "600",   fontSize: "16px",   lineHeight: "22px",   verticalAlign: "top",   padding: "12px 4px"   }, "& .amount": {   fontSize: "22px",   lineHeight: "22px",   verticalAlign: "top",   display: "inline-block"}}},
    "& .btn_next_step": { minWidth: "240px", padding: "16px", [theme.breakpoints.down('sm')]: { width: '100%' }}
  },
  "& .detail_price_product": {
    [theme.breakpoints.down('sm')]: { 
      marginTop: '5px', 
      textAlign: "left", 
      "&.has_price_offer": { marginTop: 0 },
    },
    [theme.breakpoints.up('md')]: { marginBottom: "12px",},
    "& .price": { display: 'block', verticalAlign: "top", [theme.breakpoints.up('md')]: { display: "inline-block" }, "& span": { display: "inline-block", lineHeight: "28px", verticalAlign: "top", [theme.breakpoints.down('sm')]: {   lineHeight: '22px'   }, "&.currency": {   fontSize: "22px",   [theme.breakpoints.down('sm')]: { fontSize: '18px'     }   }, "&.amount": {   fontSize: "28px",   [theme.breakpoints.down('sm')]: { fontSize: '22px'     }   } }},
    "& .price_offer": { color: "red", verticalAlign: "top", display: "inline-block", textDecoration: "line-through", [theme.breakpoints.up('md')]: { marginTop: "4px", marginRight: "8px" }, [theme.breakpoints.down('sm')]: { fontSize: "12px", display: "block", marginTop: "-2px", marginBottom: "-3px" }},
    "& .price_perperson": {
      display: 'block', 
      [theme.breakpoints.down('sm')]:{
        fontSize: 12
      }
    }    
  },
  "& .card_boxmodal_header":{
    paddingLeft: 0,
    "& .MuiCardHeader-content":{
      whiteSpace: 'normal'
    },
    "& .MuiCardHeader-avatar":{
      
      [theme.breakpoints.up('md')]: {margin: 'auto 16px'},
    }
  },
  "& .button_add_product": {
    [theme.breakpoints.up('sm')]: {height: 52},
    [theme.breakpoints.down('sm')]: {
      padding: '6px 8px',
      position: 'absolute',
      right: 0,
      bottom: 0
    }, 
    "& .MuiButton-label": { 
      [theme.breakpoints.down('sm')]: { 
        fontSize: '12px' ,
        "& .MuiButton-startIcon":{
          display: 'none'
         },
      },
      
      
    },
  },
  "& .button_remove_product": {[theme.breakpoints.down('sm')]: { padding: '6px 8px'},"& .MuiButton-label": { [theme.breakpoints.down('sm')]: { fontSize: '13px' }},},
  "& .card_item": {backgroundColor: 'white',border: "1px solid white",marginBottom: '12px',"&:hover": { border: "1px solid", backgroundColor: 'white', borderColor: styles.linkColor}, "& .step_payment_avatar": { backgroundColor: 'white'}},
  "& .card_footer": {
    [theme.breakpoints.up('md')]: { 
      //borderTop: '1px solid #dddddd',
      backgroundColor: '#f8f8f8'
    },
    
  },
  "& .card_primary_color.MuiPaper-root": { 
    [theme.breakpoints.down('sm')]: { backgroundColor: styles.cards.header.background }, 
    "& .card_footer": { 
      [theme.breakpoints.down('sm')]: { 
        color: styles.cards.header.color, 
        padding: '8px', margin: '0 8px 8px', 
      }, 
      "& .card_footer-total":{
        marginBottom: 5,
        [theme.breakpoints.down('sm')] :{
          marginBottom: 4
        },
        "& .MuiTypography-root":{
          textTransform: 'uppercase'
        }
      },
      "& .card_footer-quotes":{
        fontSize: 12
      },
      "& .text-offer":{
        [theme.breakpoints.down('sm')]:{
          color: styles.cards.header.color, 
        }
      },
      "& p": { 
        [theme.breakpoints.down('sm')]: {   
          fontSize: "18px",   
          lineHeight: 1,   
        } 
      }, 
      "& .amount": { 
        [theme.breakpoints.down('sm')]: {fontSize: "22px"} 
      } 
    }, 
    "& .MuiCardHeader-root": { 
      color: styles.cards.header.color, 
      backgroundColor: styles.cards.header.background, 
      padding: "12px 8px", 
      [theme.breakpoints.down('sm')]: { 
        borderRadius: 0 }, 
        "& .title_card_header": { color: styles.cards.header.color }, 
        "& .MuiCardHeader-avatar": { marginRight: "12px" }, 
        "& .MuiCardHeader-title": { fontSize: "18px" }, 
        "& .MuiAvatar-colorDefault": { color: styles.cards.header.color, backgroundColor: "rgba(255,255,255,0.1)", overflow: "visible" }, 
        "& .MuiTypography-colorTextSecondary": { color: styles.cards.header.color }, 
        "& .MuiIconButton-root": { color: "rgba(255,255,255,0.55)", "&:hover": { color: "rgba(255,255,255,0.8)" } }, 
        "& .info_charge_button.MuiIconButton-root": { color: "white" }, 
        "& .MuiIcon-root": { [theme.breakpoints.down('sm')]: { fontSize: "1.3rem", lineHeight: "1.3rem" } } 
      }
  },
  "& .MuiPickersToolbar-toolbar":{color: styles.cards.header.color, backgroundColor: styles.cards.header.background },
  "& .dialog_content_resume": {padding: '0', "& .detail_item_list": { "& p":{ fontSize: "14px" }}},
  "& .details_products": {padding: '26px 12px'},
  "& .modal_detail_purchase": {
    "& .purchase_card_modal": { color: styles.cards.header.color, backgroundColor: styles.cards.header.background, borderRadius: 0, boxShadow: 'none'
    
  },
    "& .MuiDialogTitle-root": { padding: 0},
    "& .MuiDialog-paper": { backgroundColor: styles.body.background, [theme.breakpoints.down('sm')]: { borderRadius: 0, margin: 0, maxHeight: '100%', minHeight: '100vh' }}
  },
  "& .MuiBackdrop-root": {backgroundColor: styles.modal.backdrop},
  //"& .MuiBadge-colorPrimary": {backgroundColor: styles.badge.background, color: styles.badge.color},
  "& .card_detail_header .MuiCardHeader-avatar": {fill: styles.headings.alternative.color},
  "& .card_detail": {
    "& .card_detail_header": { 
      color: styles.headings.alternative.color,
      border: 'none',
      backgroundColor: "#f8f8f8"
    },
    "& .header_step":{display: 'none'},
    "& .steps_container": {"& .form-passenger": {padding: '12px'}},
    "& .form-passenger": {padding: '12px'},
    "& .form-assists-contact": {padding: '12px'}
  },
  "& .MuiChip-root": { backgroundColor: styles.chipColor.background, color: styles.chipColor.color, "&.MuiChip-sizeSmall": { "& .MuiIcon-root": { fontSize: "0.8125rem", lineHeight: "0.8125rem", color: styles.chipColor.color }}},
  "& .promo_label": {color: styles.promoLabel.color},
  "& .triangle": {borderColor: styles.promoLabel.borderColor},
  "& .booking_status_ko":{ [theme.breakpoints.up('md')]: {paddingTop: '40px!important'}},
  "& .col_content": {[theme.breakpoints.up('md')]: { paddingTop: '40px!important', maxWidth: '70%', flexBasis: '70%'}},
  "& .col_sidebar": {[theme.breakpoints.up('md')]: { paddingTop: '40px!important', maxWidth: '30%', flexBasis: '30%'}},
  "& .detail_item_header": {"& .detail_item_image": { [theme.breakpoints.down('sm')]: { maxHeight: "80px", margin: "auto" }}},
  "& .custom_icon": { width: '24px', height: '24px'},
  "& .detail_item_list": {overflow: "hidden",
    "& .has_icon": { paddingLeft: "16px", position: "relative", "& .custom_icon": {width: '24px', position: "absolute", left: "0px", top: "4px"}}, 
    "& .incluye": {
      "& .custom_icon": {
        color: styles.successColor
      },
      "& .custom_icon_alt": {
        color: styles.iconColor
      }
    },
    "& .material-icons": { fontSize: "13px", position: "absolute", left: "0px", top: "4px"},
    "& .check-incluye": { "& .material-icons": { color: styles.successColor }},
    "& .category_stars": { "& .material-icons": { position: "relative", left: "inherit", fontSize: '18px' }, "& .active":{ color: 'orange!important', "&. material-icons":{color: 'orange!important'}}},
    "& .subtitle_detail_search": { marginBottom: '10px'},
    "& .subtitle_detail_product": { marginBottom: '2px'},
    "& .detail_item_header": { marginBottom: '10px'},
    "& .item_detail_search": { display: 'inline-block', marginRight: '10px', fontSize: '14px'},
    "& .item-inline":{ display: 'inline-block',
      "& p":{fontSize: '14px', position: "relative", "&.has_icon": { paddingLeft: "20px", paddingRight: "12px" }, 
        "& .custom_icon": { width: '16px', height: '16px', top: "2px", color: styles.iconColor, position: "absolute", left: "0"},
        "& .description": { display: 'block'}
      }
    }    
  },
  "& .CardOneCol":{
    "& .detail_item_image": {
      marginLeft: '10px', position: "relative", top: "3px"
    },
    "& .detail_item_list": {
      "& .has_icon": { paddingLeft: "25px!important"},
      "& .list_item":{marginBottom: '4px'},
      "& .detail_product_item": {
        "& .has_icon":{
          minHeight: '42px', marginBottom:'4px'
        }
      },
      
    }
  },
  "& .CardOneColV2brandedFares":{
    "& .detail_item_list": {
      "& .list_item":{
        marginBottom: 8
      },
      "& .has_icon": { 
        paddingLeft: "22px!important",
        "& .custom_icon": { width: '16px', height: '16px', top: 1, opacity: '.4', }
      },
      "& .rate_type": {
        "& .has_icon": {"& .custom_icon": { width: '16px', height: '16px', color: styles.iconColor }},
        "& .title":{display: 'block', fontWeight:'600', fontSize: '14px', color: styles.iconColor},
        "& .description":{display: 'block'}
      },
      "& .subtitle_detail_product": { marginBottom: '2px', "& p": {fontWeight: "bold"} },
      "& .item_detail_search": {
        display: 'block'
      },
      "& .img_product img":{ marginLeft: "10px"},
      "& .title":{ opacity: '.4', display: 'block' },
      "& .description":{ opacity: '.4', display: 'block', fontSize: 12 },
      "& .incluye":{
        "& .title":{fontWeight: 'bold', opacity: 1},
        "& .description":{opacity: 1},
        "& .has_icon": { 
          "& .custom_icon": { opacity:1 }
        },

      } 
    }
  },
  "& .CardOneColV2":{
    "& .detail_item_list": {
      "& .has_icon": { 
        paddingLeft: "22px!important",
        "& .custom_icon": { width: '16px', height: '16px' }
      },
      "& .rate_type": {
        "& .has_icon": {"& .custom_icon": { width: '16px', height: '16px', color: styles.iconColor }},
        "& .title":{display: 'block', fontWeight:'600', fontSize: '14px', color: styles.iconColor},
        "& .description":{display: 'block'}
      },
      "& .subtitle_detail_product": { marginBottom: '2px', "& p": {fontWeight: "bold"} },
      "& .item_detail_search": {
        display: 'block'
      },
      "& .img_product img":{ marginLeft: "10px"} 
    }
  },
  "& .CardOneColV3":{
    "& .detail_item_list": {"& .item_detail_search": { fontSize: 'inherit'},}
  },
  "& .text-offer": { color: styles.offerColor, fontWeight: '500'}, 
  
  "& .bonificacion": { color: styles.offerColor, fontWeight: '500'},
  "& .amount": { fontSize: "22px", fontWeight: '600', lineHeight: '22px', "& span": { fontSize: "18px"}},
  "& .purchase_card": {
      marginBottom: "20px", 
      /* "& .MuiCardContent-root": {
        padding: "12px"
      }, */
      "& .card_footer": { 
        padding: "16px"
      },
    "& .installment-amount":{fontSize: '14px', marginTop: '5px'},
    
    "& .item_resume_price": { padding: "12px", border: "1px solid white", borderRadius: "12px", marginTop: "16px"},
    "& .MuiCardHeader-root": {
      [theme.breakpoints.down('sm')]: {
        padding: '8px!important'
      },
      "& .title_card_header":{
        [theme.breakpoints.down('sm')]: {
          fontSize: "18px"
        }
      },
      "& .title_card_subheader":{
        [theme.breakpoints.down('sm')]: {
          fontSize: "12px",
          maxWidth: "216px"
        }
      }
    }
  },
  "& .item_resume":{
    "&.template-offer":{
      color: styles.offerColor,
      fontWeight: '600',
      "& p":{fontWeight: '600'}
    },
    "&.template-highlight": {
      paddingBottom: '8px',
      marginBottom: '12px',
      borderBottom: '1px solid #f0f0f0',
      //color: '#252525',
      "& p":{fontWeight: '600'},
      "& .text-right":{fontWeight: '600'}
    }
  },
  "& .total_outlined": {
      [theme.breakpoints.down('sm')]: { 
        marginBottom: '16px', textTransform: 'uppercase',
        padding: '16px 16px',fontSize: '22px',
        border: '1px solid',
        //borderColor: styles.body.color,
        borderColor: 'gray',
        borderRadius: '4px',
      }
      },
  "& .container-button-modal":{textAlign: 'center'},
  "& .thankyoupage": {
    marginTop: '25px', 
    [theme.breakpoints.up('md')]: { 
      marginTop: '0', marginBottom: '45px' 
    },
    "& .MuiTypography-h3":{ color: styles.body.color },
    "& .MuiTypography-h4":{ color: styles.body.color },
    "& .product_card_title":{ color: styles.headings.alternative.color, fontWeight: '600' },
    "& .container-button-modal":{ textAlign: 'right' },
    "& .booking_title": { marginTop: '16px', marginBottom: '16px', fontSize: 32, [theme.breakpoints.up('md')]: { marginTop: '30px', marginBottom: '30px' } },
    "& .row_booking_number": { 
      /* [theme.breakpoints.up('md')]:{maxWidth: "80%", margin: "auto"}, */
      [theme.breakpoints.down('sm')]: { textAlign: 'center' }, 
      "& .text-left": { marginTop: '16px', [theme.breakpoints.down('sm')]: {   textAlign: 'center',   marginBottom: '16px', } },
    },
    "& .row_status_booking":{
      /* [theme.breakpoints.up('md')]:{maxWidth: "80%", margin: "auto"}, */
    },
    "& .row_status_payment":{
      /* [theme.breakpoints.up('md')]:{maxWidth: "80%", margin: "auto"}, */
    },
    "& .booking_message ":{
      "& .card_divider":{
        border: 'none',
        "&:nth-child(odd)":{
          backgroundColor: "#f8f8f8"
        }
      }
    },
    "& .booking_number": { padding: '10px 16px', fontSize: '22px', border: '1px solid black', borderRadius: '4px',},
    "& .booking_message_header": { [theme.breakpoints.down('sm')]: { alignItems: 'center', justifyContent: 'center' }, "& .MuiAvatar-colorDefault": { backgroundColor: 'rgba(255,255,255,.25)', color: styles.thankyoupage.ko.color }, "& .MuiCardHeader-content": { [theme.breakpoints.down('sm')]: {   display: 'none'   } }, "& .MuiCardHeader-title": { fontSize: '22px' },},
    "& .status_booking": { "& span": { verticalAlign: 'top' }, textAlign: "left", paddingLeft: "30%"},
    "& .status_payment": { "& span": { verticalAlign: 'top' }, textAlign: "left", paddingLeft: "30%"},
    "& .booking_status_ok": { "& .booking_message_header": { backgroundColor: styles.thankyoupage.ok.backgroundColor, color: styles.thankyoupage.ok.color }, "& .booking_number": { borderColor: styles.thankyoupage.ok.backgroundColor }, "& .status_booking_icon": { color: styles.thankyoupage.ok.backgroundColor }, "& .status_payment_icon": { color: styles.thankyoupage.ok.backgroundColor },},
    "& .booking_status_ko": { "& .booking_message_header": { backgroundColor: styles.thankyoupage.ko.backgroundColor, color: styles.thankyoupage.ko.color }, "& .booking_number": { borderColor: styles.thankyoupage.ko.backgroundColor }, "& .status_booking_icon": { color: styles.thankyoupage.ko.backgroundColor }, "& .status_payment_icon": { color: styles.thankyoupage.ko.backgroundColor },},
    "& .booking_status_payment_pending": { "& .booking_message_header": { backgroundColor: styles.thankyoupage.paymentPending.backgroundColor, color: styles.thankyoupage.paymentPending.color }, 
      "& .booking_number": { borderColor: styles.thankyoupage.pending.backgroundColor }, 
      "& .status_booking_icon": { color: styles.thankyoupage.ok.backgroundColor }, 
      "& .status_payment_icon": { color: styles.thankyoupage.pending.backgroundColor }
    },
    "& .box-message-retried":{ 
      border: '1px solid', borderColor: styles.thankyoupage.ok.backgroundColor, marginBottom: 16, padding: 12, borderRadius: 5,
      alignItems: 'center',
      "&.with-error":{
        borderColor: styles.thankyoupage.ko.backgroundColor,
        "& .material-icons":{
          color: styles.thankyoupage.ko.backgroundColor,
        },
      },
      
      "& .material-icons":{
        color: styles.thankyoupage.ok.backgroundColor,
        fontSize: 34
      },
      "& .text":{
        fontSize: 16
      },
      "& .item_description":{
        marginBottom: 0,
        
        "& p": {
          fontSize: 17,
        }
      },
      
      "& .card_icon":{
        [theme.breakpoints.down('sm')]: { display: 'none'}
      }
    },
    "& .booking_status_booking_pending": { 
      "& .booking_message_header": { backgroundColor: styles.thankyoupage.paymentPending.backgroundColor, color: styles.thankyoupage.paymentPending.color }, 
      "& .booking_number": { borderColor: styles.thankyoupage.pending.backgroundColor }, 
      "& .status_booking_icon": { color: styles.thankyoupage.pending.backgroundColor }, 
      "& .status_payment_icon": { color: styles.thankyoupage.ok.backgroundColor }
    },
    "& .card_content_booking": { 
      maxWidth: '630px', 
      margin: 'auto', 
      "& .item_description:last-child": { marginBottom: 0 } 
    },
    "& .innerCardContentMessage": { 
      width: "90%", margin: "auto", 
      border: "1px solid", 
      borderRadius: "16px", overflow: "hidden", marginBottom: "25px", 
      borderColor: styles.thankyoupage.message_error.borderColor},
    "& .card_message_error": { 
      backgroundColor: styles.thankyoupage.message_error.backgroundColor, 
      color: styles.thankyoupage.message_error.color, 
      borderColor: styles.thankyoupage.message_error.backgroundColor, 
      position: 'relative', 
      fontSize: "18px",
    "& .icon_card_message": { color: styles.thankyoupage.message_error.color, top: "8px", left: "8px", position: "absolute", fontSize: "30px" }},
    "& .item_description": { 
      marginBottom: "16px" ,
      "& p":{
        fontSize: 18,
      },
    },
    
    "& .card_detail_products_booking": { 
      "& .card_detail_header": { 
        border: 'none',
        backgroundColor: "#f8f8f8" 
      }, 
      "& .card_divider":{border: 'none'},
      "& p":{ marginBottom: '0!important' },
      "&.cardsProductsDetailOpen": {
        "& .cardDetails":{
          "& .cardDetails_header": {marginBottom: '15px'},
          "& .componentDetailItems":{ 
            paddingBottom: "0", 
            "&:last-child":{  marginBottom: "0", border: "none" }
          },
          "& .detail_item_list": {
            marginBottom: '15px!important',
            fontSize: '14px',
            "& p":  {fontSize: '14px' }
          }
        },
        "& .advice-restrictions , & .messages_advice:not(.advice-fechas-flexibles)":{ backgroundColor: '#f8f8f8', marginBottom: "16px !important"}
      }
    },
    "& .detail_item":{ marginBottom: '16px' }
  },
  "& .title_detail_confirmation": { marginBottom: '16px' },
  "& .product-treatment": { color: styles.headings.h1.color, padding: "12px", background: "aliceblue", backgroundColor: "#f7f7f7", margin: "12px 12px 0", textTransform: "uppercase", fontSize: "16px"},
  "& .form-passenger": { 
    marginBottom: '35px', 
    "& .paper": { marginBottom: '0' }, 
    "& .title-form-passenger":{marginBottom: '22px' },
    "& .title-pax":{ fontWeight: '600', fontSize: "18px", opacity: ".7" },
    "& .passenger-pax":{
      marginBottom: '16px'
    } 
  },
  "& .card_detail_body_component":{
    "& .form-passenger":{
      marginBottom: '5px'
    }
  },
  "& .terminosycondiciones": { 
    "& .error":{
      color: styles.errorColor
    }, 
    [theme.breakpoints.down('sm')]:{
      "& .MuiTypography-body1": {
        fontSize: '14px'
      }
    } 
  },
  "& .status-message":{
    padding: '12px',
    border: '1px solid',
    margin: '16px 12px 16px',
    borderRadius: '4px',
    paddingLeft: '45px',
    position: "relative",
    fontSize: '0.875rem',
    "& .MuiIcon-root":{
      position: "absolute", 
      left: "12px",
      fontSize: '1.4rem'
    },
    "&.info-message":{
      borderColor: styles.infoColor,
      //color: styles.infoColor,
      color: styles.infoColor,
      "& .MuiIcon-root": {
        color: styles.infoColor,
      }
    },
    "&.error-message":{
      borderColor: styles.errorColor,
      //color: styles.errorColor,
      color: 'rgb(97, 26, 21)',
      "& .MuiIcon-root": {
        color: styles.errorColor,
      }
    }
  },
  "& .headroom":{ zIndex: '100!important' },
  "& .slick-slider":{ paddingBottom: '10px', "& .slick-dots":{ bottom: 0 } },
  "& .errorPage":{ 
    [theme.breakpoints.up('md')]: { 
      maxWidth: "1240px",
      padding: "30px 20px 30px 58px",
      width: "100%",
      margin: "25px auto",
      background: "rgb(255 255 255 / 55%)",
      borderRadius: "8px"
    }, 
    "& .title_step":{ [theme.breakpoints.up('md')]: { marginTop: '96px', marginBottom: '20px' } },
    "& .description": { marginTop: '16px', marginBottom: '25px', }, "& .img-error":{ maxWidth: '510px' } 
  },
  "& .messageConditions": {
    "& p":{ position: "relative", paddingLeft: "25px", fontSize: "12px"},
    "& .MuiIcon-root":{ fontSize: "22px", position: "absolute", left: "0", top: "-3px", color: styles.iconColor }
  },
  "& .col_content_copyright":{
    [theme.breakpoints.up('md')]:{    
      //marginTop: '-86px'
      width: '100%',
      position: 'absolute',
      bottom: 0
    }
  },
  "& .copy-leyend":{
    paddingLeft: '30px',
    position: 'relative',
    margin: '15px 0',
    "& .MuiIcon-root":{
      fontSize: "22px",
      position: 'absolute',
      left: 0,
      color: styles.linkColor
    }
  },
  "& .paper-detail-payment":{
    padding: "16px",
    paddingLeft: "50px",
    position: "relative",
    "& .material-icons": {
      color: styles.iconColor,
      position: "absolute",
      top: "14px",
      left: "14px",
    }
  },
  "& .MuiChip-root.name_user":{
    backgroundColor: "#f8f8f8!important",
    color: styles.body.color,
    border:'none',
    marginRight: 0,
    marginLeft: 'auto',
    "& .name":{
      color: styles.brandPrimary.value,
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    "& .MuiChip-icon":{color: styles.brandPrimary.value}
  },
  "& .card_credit_card":{
    border: '1px solid #cccccc',
    borderColor: 'transparent',
    marginBottom: 16,
    "&:hover":{
      border: '1px solid #cccccc',
      borderColor: styles.linkColor,
      backgroundColor: '#f9f8ff'
    },
    "& .MuiCardHeader-action":{
      //margin: 0,
      margin: 'auto'
    },
    [theme.breakpoints.up('md')]:{
      marginBottom: 25
    },
    [theme.breakpoints.down('sm')]:{
      "& .MuiTypography-root":{
      fontSize: 18,
      whiteSpace: "break-spaces"
      }
    } 
    
    
  },
  "& .MuiCardHeader-avatar":{
    color: styles.linkColor
  },
  "& .MuiFormControl-root .MuiFormLabel-root.label-focus":{
    fontWeight: '600px!important',
    color: styles.headings.h1.color, 
  },
  "& .select-quotes .MuiSelect-selectMenu":{
    height: 'auto!important'
  },
  "& .total-a-pagar":{
    fontWeight: '600'
  },
  "& .option-cuotas-info":{
    fontWeight: '600',
    whiteSpace: 'break-spaces'
  },
  "& .preloader-v2":{
    textAlign: 'center',
    margin: '40px 0',
    "& .preloader-dots":{
      color: 'white'
    },
    "& .preloader_v2_title":{
      color: 'white',
    },
    "& .card_header_preloader":{
      padding: '30px 20px 10px',
      backgroundColor: styles.brandSecondary.value
    },
    "& .card_header_preloader_icon":{
      color: styles.brandSecondary.value,
      fontSize: 30,
      
    },
    "& .card_header_preloader_description":{
      fontSize: 18,
      [theme.breakpoints.up('md')]: { 
        padding: '16px 80px'
      }
      
    },
    "& .card_header_preloader_avatar":{
      width: 60, 
      height: 60, 
      backgroundColor: 'white', 
      margin: 'auto',
      marginBottom: 10
    },
    "& .quotes-selected":{
        marginBottom: 2
    },
    
  },
  "& .total-a-pagar-steps-button":{
     
    "& .price-detail-quotes":{
        paddingBottom: '12!important',
        paddingRight: 16,
        marginTop: 5
    },
    "& .price-detail-total":{
        paddingTop: '0!important',
        paddingBottom: '0!important',
        display: 'flex',
        "& .text":{
          paddingBottom: '0!important',
          paddingTop: '0!important',
        },
        "& .price":{
            paddingBottom: '0!important',
            paddingTop: '0!important',
        },
        "& .MuiIconButton-root":{
            paddingBottom: '0!important',
            paddingTop: '0!important',
        },
    }
  },
  "& .crossell_resume_single_card": {
    "& .second_line":{
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: { fontSize: 14 }
    }
  },
  "& .checkbox-autocompletar-datos":{
    "& .MuiTypography-root":{
      [theme.breakpoints.down('sm')]: { fontSize: 14 }
    }
   
  },
  "& .card-retried-payment":{
    textAlign: 'left',
    "& .card_content_booking ": {
      maxWidth: 'inherit!important'
    }
  },
  "& .payment-form-retried":{
    "& .paper-form":{
      boxShadow: 'none',
      padding: 0
    },
    "& .btn-pagar-retried":{
      minWidth: 150
    }
  },
  "& .amount-card":{
    [theme.breakpoints.down('sm')]: { display: 'block'}
  },
  "& .rccs":{
    [theme.breakpoints.down('sm')]: { margin: '0 auto 15px'}
  },
  
  "& .title-method-selected":{
    "& .MuiIcon-root":{
      color: styles.headings.h1.color, 
    }
  },
  "& .account":{
    border: '1px solid',
    borderColor: styles.brandSecondary.value,
    width: '50%',
    margin: 'auto',
    marginBottom: 30,
    marginTop: 20,
    textAlign: 'center',
    paddingTop: 8
  },
  "& .stepConfirmation":{
    "& .checkbox-billing-autocomplete":{
      display: 'none'
    }
  } ,
  "& .avatar_purchase_detail":{
    "& .MuiBadge-badge":{
      backgroundColor: styles.badge.background
    }
  },
  "& .pendingAmount": {
    fontWeight: 600,
    fontSize: '110%'
  },
  "& .card_content_booking_retried": {
    "& .status-transaction": {
      padding: "0 18% 0 24%"
    },
    "& .booking_number":{
      padding: "5px 15px"
    },
    
  },
  "& .saldo-pendiente":{
      fontWeight: 'bold',
      //color: styles.thankyoupage.ko.backgroundColor
  },
  "& .status_booking_icon":{
    fontSize: 30,
    position: 'relative',
    top: '-2px'
  },
  "& .status_payment_icon":{
    fontSize: 30,
    position: 'relative',
    top: '-2px'
  },
  "& .card_boxmodal": {
    cursor: 'pointer',
    "& .MuiCardHeader-action":{ 
      margin: 'auto'
    }
  },
  "& #boxmodalDialog":{
    "&.MuiDialogTitle-root":{
      paddingBottom: 16,
      paddingLeft: 30,
      paddingTop: 16,
      backgroundColor: `rgba(${hexToRgb(styles.linkColor)}, 0.08)`,
      /* [theme.breakpoints.up('md')]: {
        paddingLeft: 40,
      } */
    },
    
  },
  "& .dialog_mobile":{
    "& .modal-title":{
      /* [theme.breakpoints.down('sm')]: {
        marginTop: 30,
      }, */
      paddingRight: 40,
      //marginBottom: 30,
      [theme.breakpoints.up('md')]: {
        marginTop: 30,
        paddingRight: 0,
        
      }
    }
  },
  "& .dialog-modal-crossell":{
    "& .MuiDialogContent-root":{
      padding: '20px 30px 30px',
    }
  },
  "& .button-modal-crossell":{
    padding: 0,
    width: '100%',
    cursor: 'pointer',
    marginBottom: 30
  }
 
  
},



}})(() => null);

return <ThemeProvider theme={theme}><GlobalCSS/>{children}</ThemeProvider> 
};

export default Theme;

